import React from 'react';
import BaseLayoutTemplateECMovie from 'app/src/components/environments/BaseLayoutTemplateECMovie';
import { navigate } from '@reach/router';
import HeadBase from '../components/molecules/HeadBase';

const NotFoundPage = () => {
  // 404の場合はトップページに移動する
  setTimeout(() => {
    navigate('/');
  }, 0);
  return (
    <BaseLayoutTemplateECMovie>
      <HeadBase noindex />
      <p>ページが見つかりませんでした。トップページへ移動します</p>
    </BaseLayoutTemplateECMovie>
  );
};

export default NotFoundPage;
